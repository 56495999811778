import React from "react"
import Seo from "../core/Seo"
import Homepage from "../homepage/Page"
import { TenantSignedInProvider } from "../hooks/useTenantSignedInContext"
import { TenantInfoProvider } from "../hooks/useTenantInfoContext"
import { ThemeProvider } from "styled-components"
import { SignUpProvider } from "../homepage/useSignUpContext"
import { ShowHideModalProvider } from "../homepage/useShowHideModalContext"
import useKommunicate from "../homepage/useKommunicate"

const IndexPage = () => {
  useKommunicate()
  return (
    <ThemeProvider theme={{ colors: { accent: "#3790E2" } }}>
      <TenantInfoProvider>
        <TenantSignedInProvider>
          <ShowHideModalProvider>
            <SignUpProvider>
              <Seo title="We Sparkle • Simple Tools for Solopreneurs" includeZohoTracking />
              <Homepage />
            </SignUpProvider>
          </ShowHideModalProvider>
        </TenantSignedInProvider>
      </TenantInfoProvider>
    </ThemeProvider>
  )
}

export default IndexPage
